// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: true,
  // token: 'https://phymarsrv01.physiciancorrectional.net:9443/token',
  // host: 'https://phymarsrv01.physiciancorrectional.net:9443/api',
  // token: 'https://phymarsrv01.physiciancorrectional.net/token',
  // host: 'https://phymarsrv01.physiciancorrectional.net/api',
  // host: 'http://localhost:63418/api',
  // token: 'http://localhost:63418/token',
  host: 'http://phymarbe.midnightgames.org/api',
  token: 'http://phymarbe.midnightgames.org/token',
  version: '1.5E'
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
